import React from 'react';
// import GlobalComp from '../global/globalComponent';

function NotFound() {
  return (
    <>
      Not Found
    </>
  );
}

export default NotFound;
