import React from 'react';

function Dashboard() {
  return (
    <>
      <h1>Dashboard</h1>
    </>
  );
}

export default Dashboard;
